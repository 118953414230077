<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
    rounded="sm"
    variant="transparent"
    class="d-flex flex-column tw-h-full"
  >
    <div class="card">
      <div class="card-body mb-5">
        <prozess-resource-history
          :history="items"
          :loading="loading"
          :history-pagination="pagination"
          @handleCurrentPage="handleCurrentPage"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
export default {
  props: {
    browse: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      pagination: {
        currentPage: 1,
        total: 10,
        perPage: 25,
      },
    }
  },

  watch: {
    '$route.params': {
      handler(params) {
        if (params.tab === 'history') {
          this.getHistory()
        }
      },

      immediate: true,
    },
  },

  methods: {
    async getHistory() {
      this.loading = true

      const { page } = this.$route.query
      this.pagination.currentPage = page || 1

      const query = {
        page: page ? page - 1 : 0,
        size: 25,
      }

      const { response } = await this.$async(this.browse({ query }))
      this.pagination.total = response.data.filteredItemsCount
      this.items = response.data.pageItems

      this.loading = false
    },

    handleCurrentPage(page) {
      if (!page || page === parseInt(this.$route.query.page)) {
        return
      }

      this.$router.push({
        name: this.$route.name,
        params: { tab: 'history' },
        query: { page },
      })
    },
  },
}
</script>
