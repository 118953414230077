<template>
  <div id="page--custom-entities__view">
    <sidebar-form
      :visible="showForm"
      :resource-id="resourceId"
      :table-columns="customEntity.columns"
      :relationships="customEntity.relationships"
      :service="service"
      @saved="getInitialData()"
      @close="handleFormClosure"
    />
    <div class="resource-view">
      <div
        class="resource-view__tabs"
        :class="{ 'tw-max-w-full': expanded }"
      >
        <prozess-tab-loader v-if="!tabs.length" />
        <b-tabs
          v-else
          content-class="mt-1"
          :value="tabs.indexOf(this.$route.params.tab)"
          @input="handleTabInput"
        >
          <b-tab>
            <template #title> <feather-icon icon="GridIcon" /> {{ $t('360 Overview') }} </template>
            <EntityOverviewWidgets
              :resource="{ ...resource.values, customFields: resource.values }"
              :schema-key="Schema.CUSTOM"
              :table-key="getTableKey(customEntity)"
            />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="CheckSquareIcon" /> {{ $t('Documents') }} </template>
            <document-list
              :schema-name="Schema.CUSTOM"
              :entity-type="getTableKey(customEntity)"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab
            v-for="(item, i) in customEntity.xToNRelationships"
            :key="`x-many-rel-tab${i}`"
          >
            <template #title> <feather-icon icon="CircleIcon" /> {{ $t(item.label2) }} </template>
            <x-to-many-list
              :owning-entity-id="$route.params.id"
              :owning-entity-type="$route.params.key"
              :owning-schema="Schema.CUSTOM"
              :related-entity-type="item.key2"
              :related-schema="item.schema2"
              :is-owning-entity-a-view="customEntity.type === CustomEntityType.VIEW"
              :related-entity-id="item.id"
            />
          </b-tab>
          <b-tab v-if="customEntity.type !== CustomEntityType.VIEW">
            <template #title> <feather-icon icon="ClipboardIcon" /> {{ $t('Notes') }} </template>
            <notes
              :schema-name="Schema.CUSTOM"
              :entity-type="$route.params.key"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="ListIcon" /> {{ $t('History') }} </template>
            <history :browse="getHistory" />
          </b-tab>
        </b-tabs>
      </div>
      <div
        v-show="!expanded"
        class="resource-view__details"
      >
        <div class="d-flex justify-content-end">
          <button
            id="edit-button"
            class="btn btn-primary d-flex justify-content-around align-items-center"
            :class="{
              'icon-disabled': !canUpdate,
              hidden: customEntity.type === CustomEntityType.VIEW,
            }"
            @click="canUpdate ? edit() : null"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>{{ $t('Edit') }}</span>
          </button>
          <b-tooltip
            v-if="!canUpdate"
            target="edit-button"
          >
            {{ $t('You dont have permission to edit') }}
          </b-tooltip>
        </div>

        <div class="resource-view__details-card card mt-1 resource-view__scrollable">
          <div class="card-body px-3 py-4">
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              rounded="sm"
              variant="transparent"
              style="height: 100%"
              class="d-flex flex-column"
            >
              <!-- <div>
                <div
                  v-for="(item, i) in resource.data"
                  :key="i"
                  class="d-flex mb-3"
                >
                  <div class="resource-view__field-labels d-flex flex-column">
                    <span class="resource-view__label">
                      {{ $t(item.label) }}
                    </span>
                  </div>
                  <div class="d-flex flex-column justify-content-around">
                    <div class="resource-view__field-value">
                      <span>{{ getInfoText(item) }}</span>
                      <prozess-copy-to-clipboard
                        :id="`copy-custom-field-${i}`"
                        :text="item.value || ''"
                      />
                    </div>
                  </div>
                </div>

              </div> -->

              <div
                v-for="(field, i) of fieldsManagement"
                :key="i"
                class="d-flex mb-3"
              >
                <div class="resource-view__field-labels d-flex flex-column">
                  <span class="resource-view__label">{{ $t($case.title(field.label)) }}</span>
                </div>
                <div class="d-flex flex-column justify-content-around">
                  <div class="resource-view__field-value">
                    <span v-html="getFieldObject(field.key)" />
                    <prozess-copy-to-clipboard
                      :id="`copy-key-${i}`"
                      :text="resource[camelize(field.key)] || ''"
                    />
                  </div>
                </div>
              </div>
              <div
                v-for="(item, i) in resource.xTo1Relationships"
                :key="`rel-${i}`"
                class="d-flex mb-3"
              >
                <div class="resource-view__field-labels d-flex flex-column">
                  <span class="resource-view__label">
                    {{ $t(item.label) }}
                  </span>
                </div>
                <div class="d-flex flex-column justify-content-around">
                  <span class="resource-view__field-value">
                    <router-link
                      v-if="checkRelationshipReadPermission(item.value)"
                      :to="formatRouterLink(item.value)"
                    >
                      {{ item.value.rowName }}
                    </router-link>
                    <template v-else>
                      {{ item.value.rowName }}
                    </template>
                    <prozess-copy-to-clipboard
                      :id="`copy-relationship-${i}`"
                      :text="item.value.rowName"
                    />
                  </span>
                </div>
              </div>
              <form-notification
                v-if="(readMore && showReadMore) || !showReadMore"
                :id="$route.params.id"
                :is-viewing="true"
              />
              <div
                v-if="showReadMore"
                class="d-flex justify-content-center"
              >
                <button
                  class="btn btn-primary mb-3"
                  @click="readMore = true"
                >
                  {{ $t('Read more') }}
                </button>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { customEntityRowsService } from '@/services/customEntityData'
import { toPerspective, formatRouterLink, getMainSourceTable } from '@/helpers/customEntity'
import { RelationshipFilter } from '@/constants/relationshipTypes'
import { GenericTabs, Schema } from '@/constants/app'
import { CustomEntityType } from '@/constants/customEntity'
import { advancedFieldValue } from '@/helpers/customFields'
import customEntityService from '@/services/customEntity'
import CustomFieldTypes from '@/constants/customFieldTypes'
import SidebarForm from '@/views/pages/CustomEntity/Form/index.vue'
import RootEvents from '@/constants/rootEvents'
import Notes from '@/views/pages/Notes/ListOnView/index.vue'
import DocumentList from '@/views/pages/Documents/ListOnView/index.vue'
import History from '@/views/shared/History/ListOnView.vue'
import ProzessTabLoader from '@/@core/components/ProzessTabLoader.vue'
import XToManyList from '@/views/pages/CustomEntity/List/XToManyList.vue'
import ProzessCopyToClipboard from '@/@core/components/ProzessCopyToClipboard.vue'
import FormNotification from '@/components/Forms/FormNotification.vue'
import EntityOverviewWidgets from '@/components/EntityOverview/EntityOverviewWidgets.vue'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'
import fieldMixins from '@/mixins/fields'
import executeQueryFieldMixin from '@/mixins/executeQueryField'

const { X_TO_ONE, X_TO_MANY } = RelationshipFilter

export default {
  components: {
    SidebarForm,
    Notes,
    DocumentList,
    FormNotification,
    History,
    ProzessTabLoader,
    XToManyList,
    ProzessCopyToClipboard,
    EntityOverviewWidgets,
  },

  mixins: [
    fieldMixins,
    executeQueryFieldMixin({
      schemaKey: Schema.CUSTOM,
    }),
  ],

  data() {
    return {
      CustomEntityType,
      Schema,
      service: null,
      resourceId: null,
      showForm: false,
      customEntity: {
        columns: [],
        relationships: [],
        xTo1Relationships: [],
        xToNRelationships: [],
      },
      fieldsManagement: [],
      readMore: false,
      loading: false,
      resource: {
        xTo1Relationships: [],
      },
      tabs: [],
      CustomFieldTypes,
    }
  },

  computed: {
    canUpdate() {
      return this.$can('Update', this.permissionName)
    },

    fieldsToShow() {
      return this.readMore ? this.fieldsManagement : this.fieldsManagement.slice(0, 8)
    },

    permissionName() {
      return this.$route.params.key
    },

    showReadMore() {
      return this.fieldsManagement.length >= 9 && !this.readMore
    },

    expanded() {
      return this.$route.params.tab === GenericTabs.OVERVIEW_360
    },
  },

  watch: {
    '$route.params.id': {
      handler() {
        this.getInitialData()
      },
    },
  },

  created() {
    this.getInitialData()
  },

  methods: {
    /**
     * Returns Read Permission for the Custom Entity's Relationship
     * based on `schemaName` and `tableKey` (Standard/Custom Entity key).
     *
     * This function needs to be adjusted when a new Standard Entity is added to the system
     */
    checkRelationshipReadPermission({ schemaName, tableKey }) {
      if (schemaName === 'crm') {
        return this.$can('Read', 'CRM')
      }

      if (schemaName === 'core') {
        const entities = {
          note: 'Note',
          document: 'Document',
        }

        return this.$can('Read', entities[tableKey])
      }

      /**
       * Returns Read Permission for Custom Entities
       * e.g. $can('Read', 'sales')
       */
      return this.$can('Read', tableKey)
    },

    edit() {
      this.showForm = true
    },

    formatResourceData() {
      /**
       * Formats Custom Entity's fields data
       * for easy displaying of `key`, `value`, `type` and `label`
       */
      this.resource.data = Object.keys(this.resource.values).reduce((data, key) => {
        const column = this.customEntity.columns.find(col => col.key === key)

        if (column) {
          data.push({
            key,
            value: this.resource.values[key],
            type: column.type,
            label: column.label,
          })
        }

        return data
      }, [])
    },

    formatResourceRelationships() {
      /**
       * Prepares `resource.xTo1Relationships` data.
       * Makes sure that "1to1" and "Nto1" Relationships are
       * the only ones that will be displayed on the Side Details Panel
       */
      this.resource.xTo1Relationships = this.resource.connections.reduce((relationships, connection) => {
        // Finds the `connection.tableKey`'s Relationship Details
        const relationship = this.customEntity.xTo1Relationships.find(rel => connection.tableKey === rel.key2)

        /**
         * Checks if the Relationship details is found
         * and if the Custom Entity has existing Connections/Relationships
         */
        if (relationship && connection.rows[0]) {
          relationships.push({
            key: relationship.key2,
            label: relationship.label2,
            schema: relationship.schema2,
            value: connection.rows[0],
            type: relationship.type,
          })
        }

        return relationships
      }, [])
    },

    formatRelationshipsByPerspective() {
      const { xTo1, xToN } = toPerspective(this.customEntity.key, this.customEntity.relationships, [X_TO_ONE, X_TO_MANY])

      this.customEntity.xTo1Relationships = xTo1
      this.customEntity.xToNRelationships = xToN
    },

    formatRouterLink,

    async getCustomEntityData() {
      const { response } = await this.$async(customEntityService.getOne(this.$route.params.key))

      this.customEntity = response.data
    },

    getFieldObject(key) {
      const findResource = this.resource.data?.find(resource => resource.key === key)
      return findResource ? this.getInfoText(findResource) : ''
    },

    getInfoText(item) {
      item.value = advancedFieldValue(item.value)

      if (typeof item.value === 'object') {
        return item.value ? item.value.map(_item => _item.name).join(',') : ''
      }

      if (item.type === CustomFieldTypes.Percent) {
        return item.value ? `${this.$n(item.value * 100)}%` : ''
      }

      if (item.type === CustomFieldTypes.Number) {
        return item.value ? this.$n(item.value) : ''
      }

      if (item.type === CustomFieldTypes.Date) {
        return item.value ? this.$d(new Date(item.value), 'short') : ''
      }

      return item.value || ''
    },

    getHistory({ query = {} }) {
      return this.service.getHistory(this.$route.params.key, this.$route.params.id, query)
    },

    async fetchFieldManagementsData() {
      const { response } = await this.$async(advancedFieldMgmt.getFieldsByEntity(this.getSchemaTableName))
      this.fieldsManagement = response.data
    },

    async getInitialData() {
      this.loading = true

      this.fetchFieldManagementsData()
      this.tabs = []
      this.service = customEntityRowsService(this.$route.params.key)

      await this.getCustomEntityData()

      const { response } = await this.$async(this.service.getOne(this.$route.params.id))

      this.resource = response.data
      this.resource.customFields = {}
      this.resourceId = this.resource.values.uuid_key
      this.setPageTitle()
      this.formatResourceData()
      this.formatRelationshipsByPerspective()
      this.unshiftXtoManyRelationshipsToTabs()
      this.formatResourceRelationships()
      this.resource.data.forEach(res => {
        if (res.type === CustomFieldTypes.Query) {
          this.resource.customFields[res.key] = ''
          this.queryFields.push({ key: res.key })
        }
      })
      this.dynamicTableKey = this.$route.params.key
      this.$lodash.invoke(this, 'prefillQueryFields')
      this.loading = false
    },

    getTableKey(customEntity) {
      return customEntity.type === CustomEntityType.VIEW ? getMainSourceTable(customEntity) : this.$route.params.key
    },

    handleTabInput(index) {
      this.$router
        .push({
          name: 'custom-entity-data-view',
          params: {
            key: this.$route.params.key,
            id: this.$route.params.id,
            tab: this.tabs[index] || null,
          },
        })
        .catch(() => {})
    },

    handleFormClosure() {
      this.showForm = false
    },

    /**
     * Check whether the Custom Entity has a `partOfName` (title) Field
     * then generates the Page Title out of it.
     */
    setPageTitle() {
      let title = ''
      this.customEntity.columns
        .filter(({ partOfName }) => partOfName)
        .map(({ key }) => key)
        .forEach(key => {
          const chunk = this.resource.values[key]
          title += chunk ? `${chunk} ` : ''
        })

      title = title.trim() || this.resource?.values?.uuid_key

      if (title) {
        this.$root.$emit(RootEvents.SET_PAGE_TITLE, title)
        this.$root.$emit(RootEvents.UPDATE_BREADCRUMB_ITEM, {
          value: {
            text: this.customEntity.label,
            active: true,
          },
        })
      }
    },

    unshiftXtoManyRelationshipsToTabs() {
      const isEntityAView = this.customEntity.type === CustomEntityType.VIEW

      const tabs = [GenericTabs.OVERVIEW_360, GenericTabs.DOCUMENTS, ...this.customEntity.xToNRelationships.map(({ key2 }) => key2)]

      if (!isEntityAView) {
        tabs.push(GenericTabs.NOTES)
      }

      this.tabs = [...tabs, GenericTabs.HISTORY]
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/resource-view.scss';
@import '@/assets/scss/company-contact-list.scss';
</style>
